/**
 * @module SiteError
 * @description
 */
import Container from '@atoms/Structures/Container';
import Heading from '@atoms/Typography/Heading';
import Section from '@molecules/Section';
import Button from '@atoms/Button';
import Icon from '@atoms/Typography/Icon';
import styles from './index.module.css';

/**
 * @function SiteError
 * @description
 */
const SiteError = ( { title } ) => (
  <Section className={ styles.section }>
    <Container>
      <Heading level="2" appearH4 content={ title } />

      <Button
        isLink
        href="/"
        title={ <><Icon icon="icon-chevron-left" />Back to home</> }
      />
    </Container>
  </Section>
);

export default SiteError;
